import React from "react";
import Layout from "../layout";
import { Link } from "gatsby";

const NotFoundPage = () => {
  return (
    <Layout
      metadata={{
        title: "Page not found",
      }}
    >
      <div className="container text-center my-5 py-5">
        <h1>Page not found</h1>
        <p>Sorry, we couldn't find what you were looking for</p>
        <Link to={"/"} className={"btn btn-primary"}>
          Visit home page
        </Link>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
